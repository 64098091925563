<template>
  <v-row justify="center" align="center">
    <v-col>
      <v-card
        class="mx-auto"
        color="grey lighten-5"
        width="800px"
      > 
        <v-flex class="py-2"></v-flex>
        <v-img class="mt-3 pt-4" height="100px" contain src="img/trans_logo.png" alt="City of Santa Clarita"></v-img>
        <!-- <v-card-title class=" headline">
        </v-card-title> -->
        <v-alert v-model="alert" type="info" dismissible>Slow down, it seems you are searching too many times. Wait 30 seconds</v-alert>
        <v-card-title  class="text-center" style="display:block;">
          Find details about your residency and zone.
        </v-card-title>
        <v-card-text>
          <v-autocomplete
            v-model="model"
            :items="items"
            :loading="isLoading"
            :search-input.sync="search"
            persistent-placeholder
            label="Residency Look-Up"
            placeholder="Enter Your Address"
            item-text="BLDG_ADRES"
            hide-no-data
            hide-selected
            return-object
            clearable
            persistent-hint
            hint="Disclaimer: Every reasonable effort has been made to ensure the accuracy of the presented information. However, the City of Santa Clarita assumes no liability for any errors or omissions."
          >
            <template v-slot:item="data">
              <template v-if="typeof data.item !== 'object'">
                <div>No Data Available</div>
              </template>
              <template v-else>
                {{ data.item.BLDG_ADRES }}
              </template>
            </template>
          </v-autocomplete>
        </v-card-text>
        <v-divider class="mb-0 pb-0"></v-divider>
        <v-expand-transition>
          <v-container class="grey lighten-5 py-2 pt-2 pb-0" v-show="ready">
            <GmapMap v-show="ready" :center="center" :options="mapOptions" :zoom="15" style="height:225px; width: 100%;" ref="map">
              <gmap-marker :position="markerPosition">
              </gmap-marker>
            </GmapMap>
            <!-- <v-progress-circular
              v-else-if="model"
              indeterminate
              color="primary"
            ></v-progress-circular> -->
            <v-row justify-center>
              <v-col :cols="12">
                <v-select
                  :items="sameFields"
                  item-text="label"
                  item-value="key"
                  label="Filter Fields"
                  v-model="tmp"
                  clearable
                  multiple
                >
                  <template
                    slot="selection"
                    slot-scope="{ item, index }"
                  >
                    <v-chip v-if="index <= 6">
                      <span>{{ mapKeys(item.key) }}</span>
                    </v-chip>
                    <span
                      v-if="index === 7"
                      class="grey--text caption"
                    >(+{{ tmp.length - 7 }} others)</span>
                  </template>
                </v-select>
              </v-col>
            </v-row>
            <v-layout row wrap justify-start>
              <v-flex v-for="(field, i) in fields" :key="i" xs12 sm6>
                <v-textarea :prepend-inner-icon="icon(field.key)" auto-grow :rows="getRows(field, i, fields)" class="pa-1" hide-details filled readonly :label="mapKeys(field.key)" :value="field.value">
                </v-textarea>
              </v-flex>
            </v-layout>
          </v-container>
        </v-expand-transition>
        <v-card-actions class="mt-5">
          <v-spacer></v-spacer>
          <v-btn
            :disabled="!model"
            color="primary"
            @click="model = null"
          >
            Clear
            <v-icon right>mdi mdi-close-circle</v-icon>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
  export default {
    data: () => ({
      descriptionLimit: 60,
      oldEntry: null,
      timer: null,
      entries: [],
      double: false,
      isLoading: false,
      model: null,
      search: '',
      filterField: '',
      alert: false,
      loading: false,
      center: {lat: 34.391664, lng: -118.542586},
      markerPosition: {lat: 34.391664, lng: -118.542586},
      mapOptions: {
      },
      mappedKeys: {
        'attributes': 'Attributes',
        'geometry': 'Geometry',
        'AIN': 'Parcel Number',
        'BLDG_ADRES': 'Street Address',
        'JURIS': 'Jurisdiction',
        'XSTREETMAJ': 'Major Intersection',
        'XSTREETMIN': 'Minor Intersection',
        'CouncilDistrict': 'Council District',
        'TRACTNUM': 'Tract Number',
        'OAKTREE': 'Oak Trees on Property?',
        'SMD': 'Streetlight Maint District',
        'ANNEX': 'Annexation Date',
        'WATERPURV': 'Water Purveyor',
        'TRASHDAY': 'Trash Collection Day',
        'SWEEPDAY': 'Street Sweeping',
        'FIREZONE': 'Within Firezone?',
        'DFIRM': 'Floodzone',
        'LAMD': 'LMD Zone',
        'ZONING': 'Zoning',
        'UDC_CODE': 'Zoning (Pre-July 2013)',
        'GENPLAN': 'General Plan Code',
        'YEARBUILT': 'Year Built (per Assessor)',
        'POOL_SPA': 'Pool or Spa',
        'RD_ZONE': "Sheriff's Reporting District",
        'ACRES': 'Acreage Size (GIS)',
      },
      icons: {
        'Street Address': 'home',
        'Jurisdiction': 'location_city',
        'Trash Collection Day': 'delete',
        'Street Sweeping': 'delete_sweep',
        'Pool or Spa': 'pool',
        'Oak Trees on Property?': 'nature',
        "Sheriff's Reporting District": 'security',
        'Water Purveyor': 'waves',
        'Within Enterprise Zone?': 'business_center',
        'Annexation Date': 'calendar_today',
        'Within Firezone?': 'whatshot',
        //'Parcel Number': '',
        //'Tract Number': '',
        //'LMD Zone': '',
        //'Zoning': '',
        //'Zoning (Pre-July 2013)': '',
        //'General Plan Code': '',
        //'Year Built': '',
        //'Acreage Size (GIS)': '',
        //'Minor Intersection': '',
        //'Major Intersection': '',
        //'Streetlight Maint District': '',
      },
      tmp: [],
    }),

    computed: {
      ready () {
        return this.model && !this.loading
      },
      sameFields () {
        if (!this.model) return []
        var items = Object.assign({}, this.model)
        delete items.attributes
        delete items.geometry
        return Object.keys(items).map(key => {
          return {
            label: this.mapKeys(key),
            key: key,
            value: this.model[key] || 'N/A'
            //value: !this.model[key].isEmpty ? this.model[key] : 'N/A'
          }
        })
      },
      fields () {
        if (!this.model) return []
        var items = Object.assign({}, this.model)
        delete items.attributes
        delete items.geometry
        if (!this.tmp.length) this.model = this.model
        if(this.tmp.length) {
          const allowed = this.tmp
          const filtered = Object.keys(items)
            .filter(key => allowed.includes(key))
            .reduce((obj, key) => {
              obj[key] = items[key];
              return obj;
            }, {});
          
          items = filtered
        }

       
        return Object.keys(items).map(key => {
          if(this.model[key] == null) {
            return {
              key: key,
              value: 'N/A'
            }
          }
          else
            return {
              key: key,
              value: !this.model[key].isEmpty && this.model[key] != ' '  ? this.model[key] : 'N/A'
            }
        })
      },
      items () {
        return this.entries.map(entry => {
          return Object.assign({}, entry, entry.attributes)
        })
      }
    },

    watch: {
      search (val) {
        if(!this.search) return;
        if(this.search.length < 2) return;
        if (this.timer) {
            clearTimeout(this.timer);
            this.timer = null;
        }
        this.timer = setTimeout(() => {
          this.isLoading = true
          
          // Lazily load input items
          //AIN,ST_NUM,ST_NAME,BLDG_ADRES,XSTREETMIN,XSTREETMAJ,TRACTNUM,OAKTREE,SMD,ANNEX,WATERPURV,TRASHDAY,SWEEPDAY,FIREZONE,LAMD,ZONING,UDC_CODE,GENPLAN,YEARBUILT,POOL_SPA,RZONE_RDISTRICT,ACRES
          //"https://maps.santa-clarita.com/arcgis/rest/services/Property/MapServer/2/query?where=BLDG_ADRES+LIKE+%27%25"+ this.search +"%25%27&text=&objectIds=&time=&geometry=&geometryType=esriGeometryEnvelope&inSR=&spatialRel=esriSpatialRelIntersects&distance=&units=esriSRUnit_Foot&relationParam=&outFields=AIN%2CST_NUM%2CST_NAME%2CBLDG_ADRES%2CXSTREETMIN%2CXSTREETMAJ%2CTRACTNUM%2COAKTREE%2CSMD%2CANNEX%2CWATERPURV%2CTRASHDAY%2CSWEEPDAY%2CFIREZONE%2CLAMD%2CZONING%2CUDC_CODE%2CGENPLAN%2CYEARBUILT%2CPOOL_SPA%2CRD_ZONE%2CRDISTRICT%2CACRES&returnGeometry=true&returnTrueCurves=false&maxAllowableOffset=&geometryPrecision=&outSR=4326&havingClause=&returnIdsOnly=false&returnCountOnly=false&orderByFields=&groupByFieldsForStatistics=&outStatistics=&returnZ=false&returnM=false&gdbVersion=&historicMoment=&returnDistinctValues=false&resultOffset=&resultRecordCount=15&returnExtentOnly=false&datumTransformation=&parameterValues=&rangeValues=&quantizationParameters=&featureEncoding=esriDefault&f=pjson"
          let replacedString = this.search.replace(/#/g, '%23');
          var query = "https://maps.santa-clarita.com/arcgis/rest/services/Property/MapServer/2/query?where=BLDG_ADRES+LIKE+%27%25"+ replacedString +"%25%27&text=&objectIds=&time=&geometry=&geometryType=esriGeometryEnvelope&inSR=&spatialRel=esriSpatialRelIntersects&distance=&units=esriSRUnit_Foot&relationParam=&outFields=BLDG_ADRES%2CJURIS%2CAIN%2CTRACTNUM%2CXSTREETMAJ%2CXSTREETMIN%2CCouncilDistrict%2COAKTREE%2CSMD%2CWATERPURV%2CANNEX%2CTRASHDAY%2CSWEEPDAY%2CLAMD%2CFIREZONE%2C%2CZONING%2CUDC_CODE%2CGENPLAN%2CYEARBUILT%2CDFIRM%2CPOOL_SPA%2CRD_ZONE%2CACRES&returnGeometry=true&returnTrueCurves=false&maxAllowableOffset=&geometryPrecision=&outSR=4326&havingClause=&returnIdsOnly=false&returnCountOnly=false&orderByFields=&groupByFieldsForStatistics=&outStatistics=&returnZ=false&returnM=false&gdbVersion=&historicMoment=&returnDistinctValues=false&resultOffset=&resultRecordCount=15&returnExtentOnly=false&datumTransformation=&parameterValues=&rangeValues=&quantizationParameters=&featureEncoding=esriDefault&f=pjson"
          fetch(query,  {
              method: 'POST', // or 'PUT'
            })
            .then(response => response.json())
            .then(data => {
              const entries = data.features
              this.entries = entries
              this.searchResults = entries
              this.alert = false
            })
            .catch(err => {
              if(err.response.status == 429)
                this.alert = true
            })
            .finally(() => (this.isLoading = false))
        }, 500);
      },
      model (val) {
        if(val) {
          this.loading = true
          this.markerPosition.lat = val.geometry.y
          this.markerPosition.lng = val.geometry.x
          this.$refs.map.$mapPromise.then((map) => {
            map.panTo({lat: this.markerPosition.lat, lng: this.markerPosition.lng})
          })
          this.loading = false
        }
      }

    },
    methods: {
      icon(field){
        return this.icons[field]
      },
      getRows(field, i, fields){
        if(i != 0)
          if(i % 2 != 0 && fields[i-1].value.length > 30) {
            return 1
          }
        if(field.value.length > 30) {
          return 1
        }
        return 1
      },
      mapKeys(field) {
        return this.mappedKeys[field]
      }
    } 
  }
</script>