import 'core-js/stable'
import 'regenerator-runtime/runtime'

import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import vuetify from './plugins/vuetify';
import 'roboto-fontface/css/roboto/roboto-fontface.css'
import '@mdi/font/css/materialdesignicons.css'
import * as VueGoogleMaps from 'vue2-google-maps'

import 'whatwg-fetch'


Vue.use(VueGoogleMaps, {
  load: {
    key: 'AIzaSyDKVsRqiLLPd_TmbcPhdQFiuBdFpqmwcyY',
  },
  // Demonstrating how we can customize the name of the components
  installComponents: true,
});
Vue.component('google-map', VueGoogleMaps.Map);

Vue.config.productionTip = false

new Vue({
  vuetify,
  render: h => h(App)
}).$mount('#app')
